import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Contact from "../components/Contact";
import FooterContact from "../components/FooterContact";

const ContactPage = ({ data, pageContext }) => {
  return (
    <Layout pageTitle="Kapcsolat | MyFoodProtocol" skipRecaptcha={true}>
      <Header inner={true} btnClass="main-nav__btn-two" />
      <MobileMenu inner={true} />
      <PageHeader
        pageHeading={pageContext.title ? pageContext.title : "Kapcsolat"}
        breadcrumb={[{ url: "/kapcsolat", title: "Kapcsolat" }]}
      />

      <div className="my-5">
        <Contact data={data} />
      </div>
      <FooterContact />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactQuery {
    strapiContact {
      Title
      Content {
        data {
          Content
        }
      }
    }
  }
`;
