import React, { useState } from "react";
import axios from "axios";

import FooterLogo from "../assets/images/logo-1-1.png";
import EUblokk from "../assets/images/EUblokk.png";
import tiktok from "../assets/images/tik-tok.png";
import AppleDownload from "../assets/images/download-apple.png";
import GoogleDownload from "../assets/images/download-google-play.png";
import hiventuresLogo from "../assets/images/hiventures-logo.png";

const FooterContact = () => {
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <a href="index.html">
                    <img src={FooterLogo} width="129" alt="awesome post" />
                  </a>
                  <div className="footer-widget mt-4">
                    <h3 className="footer-widget__title mb-4">Kövess minket</h3>
                    <div className="footer-widget__social mb-3">
                      <a
                        href="https://www.facebook.com/myfoodprotocol"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/myfoodprotocol"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCI7FYoyoBmw5uXjoUckgMHw"
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                      <a href="#none" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="footer-widget__social">
                      <a href="#none" target="_blank">
                        <i className="fab fa-linkedin"></i>
                      </a>
                      <a href="#none" target="_blank">
                        <img
                          src={tiktok}
                          className="custom-icon"
                          alt="tik-tok"
                        />
                      </a>
                      <a href="/kapcsolat" target="_blank">
                        <i className="fa fa-envelope"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">Töltsd le itt</h3>
                    <div className="d-flex flex-column">
                      <a href="#none" className="btn-img mb-3">
                        <img
                          src={AppleDownload}
                          alt="Download on the Apple Store"
                        />
                      </a>
                      <a href="#none" className="btn-img mb-3">
                        <img src={GoogleDownload} alt="Get it on Google Play" />
                      </a>
                      <a href="#none" className="btn btn-dark">
                        <i className="fa fa-desktop"></i>
                        <span className="ms-1 font-weight-bold">Web App</span>
                      </a>
                    </div>
                  </div>

                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">
                      Kérdések, kapcsolat
                    </h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/kapcsolat">Kapcsolat</a>
                      </li>
                      <li>
                        <a href="/gyik">GY.I.K.</a>
                      </li>
                      <li>
                        <a href="/egyuttmukodes">Együttműködés</a>
                      </li>
                      <li>
                        <a href="/media-kit">MediaKIT</a>
                      </li>
                    </ul>
                  </div>

                  <div className="footer-widget">
                    <h3 className="footer-widget__title mb-4">Információk</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <a href="/impresszum">Impresszum</a>
                      </li>
                      <li>
                        <a href="/aszf">ÁSZF</a>
                      </li>
                      <li>
                        <a href="/adatvedelmi-nyilatkozat">
                          Adatvédelmi nyilatkozat
                        </a>
                      </li>
                      <li>
                        <a href="/biztonsag">Biztonság</a>
                      </li>
                      <li>
                        <a href="/cookie-tajekoztato">Cookie tájékoztató</a>
                      </li>
                      <li>
                        <a href="/jogi-nyilatkozat">Jogi nyilatkozat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-4">
                <div className="position-relative">
                  <a
                    href="https://www.hiventures.hu"
                    className="hive-logo"
                    target="_blank"
                  >
                    <img src={hiventuresLogo} alt="Hiventures" />
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <p>
                  © Copyright 2021 - 2023 Minden jog fenntartva!
                  <br />
                  My Food Protocol AI Analytics Kft.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="./szechenyi2020.html" className="eu-logo" target="_blank">
          <img src={EUblokk} alt="SZÉCHENYI 2020" />
        </a>
      </footer>
    </div>
  );
};

export default FooterContact;
