import React, { useState } from "react";
import axios from "axios";
import BlockTitle from "./BlockTitle";
import ContactBgShape from "../assets/images/shapes/contact-bg-shape-1-1.png";
import ContactImage from "../assets/images/resources/contact-1-1.jpg";
import parse from "html-react-parser";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";

const Contact = ({ data, pageContext }) => {
  const [visible, setVisible] = useState(true);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  // console.log("data", data);

  const submit = (token) => {
    const form = document.getElementById("contact-form");
    const formData = {
      name: form.elements.name.value,
      email: form.elements.email.value,
      subject: form.elements.subject.value,
      phone: form.elements.phone.value,
      message: form.elements.message.value,
      terms: form.elements.terms.checked,
      token: form.elements.terms.checked,
    };
    const err = [];
    if (!formData.terms) {
      err.push("Az adatvédelmi nyilatkozat elfogadása kötelező!");
    }
    if (!formData.name) {
      err.push("A név mező kötelező!");
    }
    if (!formData.email) {
      err.push("Az e-mail cím mező kötelező!");
    } else if (
      !formData.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      err.push("Nem megfelelő e-mail cím!");
    }
    if (!formData.message) {
      err.push("Az üzenet mező kötelező!");
    }
    // console.log("err", err);
    if (err.length) {
      setErrors(err);
    } else {
      setErrors([]);
      axios
        .post(`${process.env.API_URL}/contact`, formData)
        .then((response) => {
          if (response.data === true) {
            setVisible(false);
            setMsg("Köszönjük az üzenetét!");
          } else {
            setErrors(["Hiba történt az adatok küldése során!"]);
          }
        });
    }
    return false;
  };

  return (
    <section className="contact-one">
      <ReCaptcha
        action="contact"
        badge="inline"
        size="invisible"
        siteKey="6LfEOxEkAAAAANhe4GcEWOM4-yA55Op3b8L_wYWt"
        onVerify={(token) => submit(token)}
        submitted={submitted}
      />
      <img
        src={ContactBgShape}
        className="contact-one__bg-shape-1"
        alt="awesome post"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            {visible && (
              <form
                id="contact-form"
                className="contact-form-validated contact-one__form"
              >
                <BlockTitle
                  textAlign="left"
                  paraText="Kapcsolat"
                  titleText={data.strapiContact.Title}
                />
                <p>{parse(data.strapiContact.Content.data.Content)}</p>
                <div className="row">
                  <div className="col-lg-6">
                    <input type="text" placeholder="Név *" name="name" />
                  </div>
                  <div className="col-lg-6">
                    <input type="email" placeholder="E-mail *" name="email" />
                  </div>
                  <div className="col-lg-6">
                    <select
                      name="subject"
                      className="custom-select custom-select-lg mb-3"
                    >
                      <option value="Általános kérdés">Általános kérdés</option>
                      <option value="Technikai probléma">
                        Technikai probléma
                      </option>
                      <option value="Partneri együttműködés">
                        Partneri együttműködés
                      </option>
                      <option value="Sajtó">Sajtó</option>
                      <option value="Egyéb">Egyéb</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <input type="text" placeholder="Telefonszám" name="phone" />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="Üzenet szövege *"
                      name="message"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="terms"
                        id="terms"
                        value={true}
                      />
                      <label className="form-check-label" htmlFor="terms">
                        Elfogadom az adatvédelmi nyilatkozatot.
                      </label>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      className="thm-btn contact-one__btn mt-3 mt-lg-0 ms-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmitted(true);
                        return false;
                      }}
                    >
                      <span>Küldés</span>
                    </button>
                  </div>
                </div>
                {errors.length > 0 && (
                  <div className="alert alert-danger w-100 mt-3" role="alert">
                    {errors.map((error, index) => (
                      <p className="mb-0" key={index}>
                        {error}
                      </p>
                    ))}
                  </div>
                )}
              </form>
            )}
            <div className="result">
              {msg && (
                <div className="alert alert-success w-100 mt-3" role="alert">
                  {msg}
                </div>
              )}
            </div>
          </div>
          <div
            className="col-lg-5 d-flex wow fadeInRight"
            data-wow-duration="1500ms"
          >
            <div className="my-auto">
              <div className="contact-one__image">
                <img src={ContactImage} alt="awesome post" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
